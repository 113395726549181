import React from 'react';

const Blog = () => {
    return (
        <div>
            <h1>My blog</h1>
            {/* Home page content */}
        </div>
    );
};

export default Blog;
