import React from 'react';

const Works = () => {
    return (
        <div>
            <h1>View my works</h1>
            {/* Home page content */}
        </div>
    );
};

export default Works;
