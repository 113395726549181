// src/pages/About.js
import React from 'react';

const About = () => {
    return (
        <div>
            <h1>About Me</h1>
            {/* About page content */}
        </div>
    );
};

export default About;
